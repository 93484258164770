import * as THREE from 'three'
import { useState, useRef, useEffect } from 'react'
import { Canvas, useFrame, useLoader } from '@react-three/fiber'
import { useCursor, MeshReflectorMaterial, useGLTF } from '@react-three/drei'
import { useRoute, useLocation } from 'wouter'
import getUuid from 'uuid-by-string';

const LONGSIDE = 1.4
const SHORTSIDE = 1

export default function App() {
  //const pexel = (id) => `http://localhost:3000/${id}.jpg`
  const pexel = (id) => `https://sample2.sodit.cyou/${id}.jpg`

  const images = [
    // Left
    { position: [-4.2, 0.2, 0.2], rotation: [0, Math.PI / 4, 0], url: pexel(1), width: LONGSIDE, height: SHORTSIDE },
    { position: [-4.2, -1.4, 0.2], rotation: [0, Math.PI / 4, 0], url: pexel(2), width: LONGSIDE, height: SHORTSIDE },
    { position: [-2.2, 0.2, -1.8], rotation: [0, Math.PI / 4, 0], url: pexel(3), width: LONGSIDE, height: SHORTSIDE },
    { position: [-2.2, -1.4, -1.8], rotation: [0, Math.PI / 4, 0], url: pexel(4), width: LONGSIDE, height: SHORTSIDE },
    //Right
    { position: [4.2, 0.2, 0.2], rotation: [0, -Math.PI / 4, 0], url: pexel(5), width: LONGSIDE, height: SHORTSIDE },
    { position: [4.2, -1.4, 0.2], rotation: [0, -Math.PI / 4, 0], url: pexel(7), width: LONGSIDE, height: SHORTSIDE },
    { position: [2.2, 0.2, -1.8], rotation: [0, -Math.PI / 4, 0], url: pexel(6), width: SHORTSIDE, height: LONGSIDE }
  ]

  return (
    <>
      <Canvas camera={{ fov: 60, position: [1, 1, 1] }}>
        <ambientLight />
        <pointLight position={[0, 10, 10]} />
        <Frames images={images} />
        <mesh
          position={[-2.12, 0, 0]}
          rotation={[0, Math.PI / 4, 0]}>
          <planeGeometry args={[6, 5]} />
          <meshStandardMaterial color="#706d68" />
        </mesh>
        <mesh
          position={[2.12, 0, 0]}
          rotation={[0, -Math.PI / 4, 0]}>
          <planeGeometry args={[6, 5]} />
          <meshStandardMaterial color="#706d68" />
        </mesh>
        <mesh receiveShadow position={[0, -2.5, 2.1]} rotation={[-Math.PI / 2, 0, -Math.PI / 4]}>
          <planeGeometry args={[6, 6]} />
          <MeshReflectorMaterial
            blur={[300, 100]}
            resolution={512}
            mixBlur={1}
            mixStrength={40}
            roughness={1}
            depthScale={1.2}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.4}
            color="#050505"
            metalness={0.5}
          />
        </mesh>
        <Model />
      </Canvas>
    </>
  )
}

function Frames({ images, q = new THREE.Quaternion(), p = new THREE.Vector3() }) {
  const screensize = useRef();
  const ref = useRef()
  const clicked = useRef()
  const [, params] = useRoute('/item/:id')
  const [, setLocation] = useLocation()
  useEffect(() => {
    if (window.innerWidth < window.innerHeight) {
      screensize.zoom = window.innerWidth > 820 ? 1.25 : 5;
      screensize.main = window.innerWidth > 820 ? 4.5 : window.innerWidth > 410 ? 12 : 16;
    } else {
      screensize.zoom = window.innerWidth > 820 ? 4 : 3;
      screensize.main = window.innerWidth > 820 ? 10 : 5;
    }

    clicked.current = ref.current.getObjectByName(params?.id)
    if (clicked.current) {
      clicked.current.parent.updateWorldMatrix(true, true)
      clicked.current.parent.localToWorld(p.set(0, 1, screensize.zoom))
      clicked.current.parent.getWorldQuaternion(q)
    } else {
      p.set(-0, 0, screensize.main)
      q.identity()
    }
  })
  useFrame((state, dt) => {
    state.camera.position.lerp(p, 0.025)
    state.camera.quaternion.slerp(q, 0.025)
  })
  return (
    <group
      ref={ref}
      onClick={(e) => (e.stopPropagation(), setLocation(clicked.current === e.object ? '/' : '/item/' + e.object.name))}
      onPointerMissed={() => setLocation('/')}>
      {images.map((props) => <Frame key={props.url} {...props} /> /* prettier-ignore */)}
    </group>
  )
}

function Frame({ url, c = new THREE.Color(), ...props }) {
  const [hovered, hover] = useState(false)
  const uuid = getUuid(url)
  useCursor(hovered)
  const texture = useLoader(THREE.TextureLoader, url)
  const framesize = useRef();
  framesize.width = props.width > props.height ? 0.3 : -0.1;
  framesize.height = props.width > props.height ? 0.1 : -0.3;

  return (
    <group {...props}>
      <mesh
        name={uuid}
        scale={[props.width, props.height, 0.05]}
        position={[0, props.width / 2, 1.4]}>
        <boxGeometry args={[props.width - framesize.width, props.height + framesize.height, 3]} />
        <meshStandardMaterial color={0xbda184} />
      </mesh>
      <mesh
        name={uuid}
        onPointerOver={(e) => (e.stopPropagation(), hover(true))}
        onPointerOut={() => hover(false)}
        scale={[props.width, props.height, 0.05]}
        position={[0, props.width / 2, 1.48]}>
        <planeGeometry />
        <meshStandardMaterial map={texture} />
      </mesh>
    </group>
  )
}

export function Model(props) {
  const { nodes, materials } = useGLTF("/vase.glb");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, -1.8, 0]} scale={[0.4, 0.7, 0.4]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.pCylinder1_lambert1_0.geometry}
              material={materials.lambert1}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/vase.glb");